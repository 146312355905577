(function ($, Drupal) {
  Drupal.behaviors.smMainMenuNavigation = {
    attach: function (context, settings) {
      /////////////////
      // The menu.
      var $mainMenuContents = $('#block-sm-custom-blocks-main-sm-menu-contents');

      // Process only expanded items (items with child).
      var $expandedLis = $mainMenuContents.find('.expanded');

      // Add interactivity to expandable items.
      $expandedLis.once().each(function () {
        var $li = $(this);
        var $arrow = $('<a href="#" class="expand-button"><span>X</span></a>');
        var $wrapper = $('<span class="parent-link"></span>');
        var $anchor = $li.children('a, .nolink');

        // Add some extra DOM elements.
        $anchor.wrap($wrapper);
        $anchor.after($arrow);

        /**
         * Arrow click handler.
         */
        $arrow.click(function (e) {
          e.preventDefault();
          $li.toggleClass('active');
          $li.find('> ul.menu').toggleClass('open');

          $li.siblings('li').each(function () {
            var $sibling = $(this);
            $sibling.removeClass('active');
            $sibling.find('> ul.menu').removeClass('open');
          });


        });

      });

      /////////////////
    }
  };

})(jQuery, Drupal);